import api from "@/base/utils/request";

//获取系统平台列表
export const systemsList = () => {
  return api({
    url: "/admin/admin/rbac_role/systems",
    method: "post"
  });
}

//获取系统平台可用角色列表
export const availableList = () => {
  return api({
    url: "/admin/admin/rbac_role/available",
    method: "post"
  });
}
