<template>
  <div class="list-page">
    <el-button size="small" class="top-btn" type="primary" @click="onAddEdit('add')">添加角色</el-button>
    <div class="list-content" v-loading="tableLoading">
      <el-table :data="tbody" style="width: 100%" class="thead-light" stripe>
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :minWidth="th.minWidth" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button size="small" type="text" @click="onRoleUser(row.id)">角色授权</el-button>
              <el-button size="small" type="text" @click="onRoleAccess(row.id)">访问授权</el-button>
              <!-- <el-button size="small" type="text" @click="onAuthorize(row.id)">数据授权</el-button> -->
              <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button>
              <el-button size="small" type="text" @click="onDisableEnable(row.id,!row.status)">
                {{row.status?'禁用':'启用'}}</el-button>
              <el-button size="small" type="text" @click="onDelete(row.id)">删除</el-button>
            </template>
            <!-- 状态 -->
            <!-- <span v-else-if="th.prop==='status'">
              {{row.status?'激活':'禁用'}}
              <list-satus-text></list-satus-text>
            </span> -->
            <template v-else-if="th.prop === 'status'">
              <list-status-text :text="row.status == 0 ? '禁用' : '激活'" :type="row.status === 1 ? 'success': 'info'" />
            </template>
            <!-- 其他 -->
            <span v-else>{{row[th.prop] || '-'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination v-if="initData" :total="initData.total" :change="onPageChange" />
    </div>
    <!-- 创建和编辑弹窗 -->
    <add-edit-dialog v-model="openAddEditDialog" :complete="getRoleList" :edit="openEdit" :data="editData" />
    <!-- 数据授权弹窗 -->
    <authorize-dialog v-model="openAuthorizeDialog" :id="AuthId"  :data="AuthData" />
    <!-- 删除弹窗 -->
    <delete-dialog v-model="openDeleteDialog" :delete-id="deleteId" :complete="getRoleList" />
  </div>
</template>
<script>
import { roleList, roleUpdateStatus,getAuthData } from "../api/role";
import Pagination from "@/base/components/Default/Pagination";
import AddEditDialog from "../components/role/AddEditDialog";
import DeleteDialog from "../components/role/DeleteDialog";
import AuthorizeDialog from "../components/role/AuthorizeDialog";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  data() {
    return {
      tableLoading: false, //表格加载中
      thead: [
        { label: "ID", prop: "id", minWidth: 80 },
        { label: "角色名", prop: "name", minWidth: 200 },
        { label: "状态", prop: "status", minWidth: 100 },
        { label: "描述", prop: "description", minWidth: 400 },
        { label: "管理用户数", prop: "users_count", minWidth: 200 },
        { label: "操作", prop: "operate", minWidth: 330, fixed: "right" },
      ],
      tbody: [],
      initData: null, //请求回来的初始数据
      openAddEditDialog: false, //显示新建&编辑弹窗
      openEdit: false, //是否开启弹窗编辑模式
      editData: {}, //编辑弹窗的数据
      deleteId: "", //删除的角色id
      openDeleteDialog: false, //是否开启删除弹窗
      openAuthorizeDialog:false, // 是否开启数据授权弹框
      // 架构数据
      AuthData:{},
      // 当前的用户Id
      AuthId:null
    };
  },
  methods: {
    //获取角色列表
    getRoleList(postData = {}) {
      this.tableLoading = true;
      roleList(postData)
        .then((res) => {
          const { data } = res;
          this.initData = data;
          this.tbody = data.data;
          this.tableLoading = false;
          // console.log(data);
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页发生变化
    onPageChange(pageObj) {
      this.getRoleList(pageObj);
    },
    //新增或者编辑
    onAddEdit(type, data) {
      switch (type) {
        case "add":
          this.editData = {}; //防止使用历史数据
          this.openEdit = false;
          break;
        case "edit":
          this.editData = data;
          this.openEdit = true;
          break;
      }
      this.openAddEditDialog = true;
    },
    // 打开数据授权 弃用
    // onAuthorize(id){
    //   this.AuthId = id;
    //     // 获取架构数据
    //     getAuthData({id}).then(res => {

    //       this.AuthData = res.data;
    //       console.log(this.AuthData)
    //       // 显示数据授权弹窗
    //       this.openAuthorizeDialog = true;
    //     })
    // },
    //更新状态
    onDisableEnable(id, status) {
      this.tableLoading = true;
      roleUpdateStatus({ id, status: status ? 1 : 0 })
        .then((res) => {
          this.$message(res.msg);
          this.getRoleList();
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //删除
    onDelete(id) {
      this.deleteId = id;
      this.openDeleteDialog = true;
    },
    //角色授权
    onRoleUser(id) {
      this.$router.push({ name: "RoleUser", params: { id } });
    },
    // 授权访问
    onRoleAccess(id) {
      this.$router.push({ name: "RoleAccess", params: { id } });
    },

  },
  created() {
    //获取角色列表
    this.getRoleList();
  },
  components: {
    Pagination,
    AddEditDialog,
    DeleteDialog,
    AuthorizeDialog,
    ListStatusText
  },
};
</script>
