<template>
  <el-dialog :visible.sync="show" title="提示" width="504px" class="dialog-vertical">
    <div class="content">您是否要删除该角色？</div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="onDelete">删除</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { roleDel } from "../../api/role";
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //删除的数据id
    deleteId: [String, Number],
    //完成后的回调
    complete: Function,
  },
  data() {
    return {
      loading: false, //删除中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    //删除
    onDelete() {
      if (!this.deleteId) {
        this.$message.warning("无法获取到删除的角色");
        return;
      }
      this.loading = true;
      roleDel({ id: this.deleteId })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
          if (this.complete) this.complete();
          this.show = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    color: #303133;
  }
}
</style>
