<template>
  <el-dialog :visible.sync="show" title="添加角色" width="504px" class="dialog-vertical" @open="onOpenDialog"
    @close="resetForm('ruleForm')">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="85px" size="medium"
        class="small-form" @submit.native.prevent>
        <!-- 角色名 -->
        <el-form-item label="角色名：" prop="name">
          <el-input v-model="form.name" placeholder="请输入角色名"></el-input>
        </el-form-item>
        <!-- 所属平台 -->
        <!-- <el-form-item label="所属平台：" prop="system">
          <el-select v-model="form.system" placeholder="请选择所属平台">
            <el-option
              v-for="sy in systemsOptions"
              :key="sy.system"
              :label="sy.name"
              :value="sy.system"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <!-- 描述 -->
        <el-form-item label="描述：" prop="description">
          <el-input v-model="form.description" placeholder="请输入描述"></el-input>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">激活</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="oncConfirm('ruleForm')">确认</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { systemsList, availableList } from "../../api/default";
import { roleSave } from "../../api/role";
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //完成后的回调
    complete: Function,
    //是否开启编辑模式
    edit: {
      type: Boolean,
      default: false,
    },
    //编辑时使用的数据
    data: Object,
  },
  data() {
    return {
      loading: false, //保存中
      form: {
        id: 0, //默认0新增
        name: "", //角色名
        // system: "", //所属平台
        description: "", //描述
        status: 1, //状态
      },
      rules: {
        name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
        description: [
          {
            required: false,
            message: "请输入描述",
            trigger: ["blur", "change"],
          },
        ],
        // system: [
        //   { required: true, message: "请选择所属平台", trigger: "change" },
        // ],
        status: [
          { required: true, message: "请选择角色状态", trigger: "change" },
        ],
      },
      systemsOptions: [{ name: "管理后台", system: "admin" }], //系统平台列表
      availableOptions: [{ id: 1, name: "超级管理员" }], //角色列表
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    //打开弹窗回调
    onOpenDialog() {
      if (this.edit) {
        this.$nextTick(() => {
          this.initForm();
        });
      }
    },
    //获取系统平台列表
    getSystemsList() {
      systemsList().then((res) => {
        const { data } = res;
        this.systemsOptions = data;
      });
    },
    //获取系统平台可用角色列表
    getAvailableList() {
      availableList().then((res) => {
        const { data } = res;
        this.availableOptions = data;
      });
    },
    //确认
    oncConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          //提交的数据
          const postData = { ...this.form };
          //请求
          roleSave(postData)
            .then((res) => {
              //运行回调
              if (this.complete) this.complete();
              //关闭
              this.loading = false;
              this.show = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //初始化表单
    initForm() {
      if (!this.data || !Object.keys(this.data).length) {
        this.$message.warning("角色数据不存在或者为空");
        return;
      }
      for (let key in this.form) {
        this.form[key] = this.data[key];
      }
    },
  },
  created() {
    //获取系统平台列表
    // this.getSystemsList();
    //获取系统平台可用角色列表
    // this.getAvailableList();
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    color: #303133;
    .small-form {
      .el-form-item--small:last-child {
        margin-bottom: 0;
      }
    }
    .authorize-select {
      & + .authorize-select {
        margin-top: 10px;
      }
    }
    .delete-select {
      margin-left: 5px;
      padding: 2px;
    }
    .add-authorize {
      margin-top: 10px;
    }
  }
}
</style>
