<template>
  <el-dialog :visible.sync="show" title="数据授权" width="504px" class="dialog-vertical" @open="onOpenDialog"
    @close="resetForm('ruleForm')">
    <div class="content">
      <el-form :model="form" ref="ruleForm" label-width="85px" size="medium" class="small-form" @submit.native.prevent>
        <!-- 状态 -->
        <el-form-item label="所属架构：">
          <el-cascader v-model="data.selected_ids" @change="cascaderChange" :options="data.list"
            :props="{ emitPath: false, label: 'name', value: 'id', children: 'children', multiple: true }">
          </el-cascader>
          <p class="info"><span class="el-icon-info"></span> 选择想要管理的组织架构，授权后将拥有管理数据的权限</p>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="oncConfirm('ruleForm')">确认</el-button>
    </template>
  </el-dialog>
</template>
<script>

import { saveAuthData } from "../../api/role";
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //完成后的回调
    complete: Function,
    //是否开启编辑模式
    edit: {
      type: Boolean,
      default: false,
    },
    id: Number,
    //编辑时使用的数据
    data: Object,
  },
  data() {
    return {
      loading: false, //保存中
      form: {
        // 架构id 数据 Json
        organization_ids: [],
      },
      // rules: {
      //   selected_ids: [
      //     { required: true, message: "请选择所属架构", trigger: "change" },
      //   ],
      // },
      systemsOptions: [{ name: "管理后台", system: "admin" }], //系统平台列表
      availableOptions: [{ id: 1, name: "超级管理员" }], //角色列表
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    // 级联选择器改变
    cascaderChange(ids) {
      // console.log(ids)
    },
    //打开弹窗回调
    onOpenDialog() {
      if (this.edit) {
        this.$nextTick(() => {
          this.initForm();
        });
      }
    },

    //确认
    oncConfirm(formName) {
      this.loading = true;
      let postData = {
        id: this.id,
        organization_ids: this.$props.data.selected_ids
      }

      // 判断有无选择，没选择就是清空所有权限
      if (!this.$props.data.selected_ids.length) {
        // 做特殊处理
        postData.organization_ids = JSON.stringify([])
      }
      // 提交的数据
      saveAuthData(postData).then(res => {
        if (res.msg === '保存成功') {
          this.$message.success(res.msg)
          this.loading = false;
          this.show = false;
        }

      })
    },
    //重置表单
    resetForm(formName) {
      // this.$refs[formName].resetFields();
    }
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    min-height: 200px;
    color: #303133;

    .small-form {
      .el-form-item--small:last-child {
        margin-bottom: 0;

      }

      p {
        margin-top: 5px;
        // color: #606266;
        font-size: 12px;
        width: 295px;
        line-height: 20px;
      }
      // .info {
      //   color: #606266;
      // }
    }

    .authorize-select {
      &+.authorize-select {
        margin-top: 10px;
      }
    }

    .delete-select {
      margin-left: 5px;
      padding: 2px;
    }

    .add-authorize {
      margin-top: 10px;
    }
  }
}
</style>
