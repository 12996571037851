import api from "@/base/utils/request";

//获取角色列表
export const roleList = data => {
  return api({
    url: "/admin/admin/rbac_role/index",
    method: "post",
    data
  });
}

//创建或保存角色列表
export const roleSave = data => {
  return api({
    url: "/admin/admin/rbac_role/save",
    method: "post",
    data
  });
}

//更新状态：禁用&启用
export const roleUpdateStatus = data => {
  return api({
    url: "/admin/admin/rbac_role/updateStatus",
    method: "post",
    data
  });
}

//删除角色
export const roleDel = data => {
  return api({
    url: "/admin/admin/rbac_role/del",
    method: "post",
    data
  });
}

//获取角色授权的用户列表
export const roleUsers = data => {
  return api({
    url: "/admin/admin/rbac_role/users",
    method: "post",
    data
  });
}

//角色授权
export const roleAuthUsers = data => {
  return api({
    url: "/admin/admin/rbac_role/authUsers",
    method: "post",
    data
  });
}


//授权访问
export const roleNodes = data => {
  return api({
    url: "/admin/admin/rbac_role/nodes",
    method: "post",
    data
  });
}


//更新节点授权
export const roleAuthNodes = data => {
  return api({
    url: "/admin/admin/rbac_role/authNodes",
    method: "post",
    data
  });
}
//获取架构数据
export const getAuthData = data => {
  return api({
    url: "/admin/admin/rbacRole/organizationAuthData",
    method: "post",
    data
  });
}
//保存数据授权
export const saveAuthData = data => {
  return api({
    url: "/admin/admin/rbacRole/dataAuth",
    method: "post",
    data
  });
}